import { useContext, useState, useEffect } from "react";
import GlobalContext from "../common/Context";
import { useForm } from "react-hook-form";
import {
  Box,
  TextField,
  Button,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  Grid,
  IconButton,
} from "@mui/material";
import * as constants from "../../constants";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoadingWheel from "../common/LoadingWheel";
import LoadingModal from "../common/LoadingModal";

const SignIn = () => {
  const [falert, setFalert] = useState(null);
  const mobileView = useMediaQuery("(max-width:743px) and (min-width:300px)");
  const inputWidth = mobileView ? "23rem" : "40.625rem";
  const global = useContext(GlobalContext);
  const [textType, setTextType] = useState("password");
  const [linkMsg, setLinkMsg] = useState(constants.LINK_LIST[0]);
  const location = useLocation();
  const email = location.state?.email;
  const currentProject = constants.PROJECTS.find(
    (project) => project.id === global.firebaseState.firebase.app().options.projectId,
  );
  const availabeProjects = constants.PROJECTS.filter((project) =>
    constants.DEV_PROJECT_IDS.includes(currentProject.id)
      ? true
      : !constants.DEV_PROJECT_IDS.includes(project.id),
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const inputStyle = {
    width: inputWidth,
    height: "4.375rem",
    backgroundColor: "",
  };
  const messageBox = {
    position: "absolute",
    margin: "auto",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "25%",
    height: "30%",
    p: "2rem",
    background: "#ffffff",
    textAlign: "center",
  };
  const fontStyle = { fontSize: "1rem" };
  const bigText = { mb: "1rem", fontSize: "1.25rem", fontWeight: 900 };
  const container = {
    p: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  const regionSelect = {
    width: "75%",
    m: "1rem 0 1.5rem",
    "& .MuiSelect-select": {
      p: "0.75rem 3rem 0.75rem 3.5rem !important",
    },
  };
  const flagDisplayBox = {
    display: "flex",
    position: "absolute",
    left: "1rem",
  };


  const onSubmit = async ({ email, password }) => {
    global.setSignError("");
    global.setSignLoad(true);
    try {
      await global.firebaseState.doSignInWithEmailAndPassword(email, password);
    } catch (error) {
      global.setSignLoad(false);
      global.setSignError(constants.SIGNIN_BAD_CREDS);
      console.error(error);
    }
  };

  const onSubmitGoogle = () => {
    global.firebaseState.signInWithGoogle().catch((error) => {
      setFalert(error.message);
    });
  };

  const handleIconClick = (type) => {
    type === "password" ? setTextType("text") : setTextType("password");
  };

  const forgotPassClick = () => {
    const projectId = global.firebaseState.firebase.app().options.projectId;
    window.open(constants.ENV_URLS[projectId] + "/passwordreset", "_blank");
  };

  useEffect(() => {
    global.firebaseState.firebase.auth().onAuthStateChanged((user) => {
      if (user) global.setSignLoad(true);
      else global.setSignLoad(false);
    });
  }, [global]);
  useEffect(() => {
    var i = 0;
    const changeMsg = () => {
      setTimeout(() => {
        setLinkMsg(constants.LINK_LIST[i + 1]);
        if (i < constants.LINK_LIST.length - 2) {
          i++;
          changeMsg();
        }
      }, 5000);
    };
    global.linking && changeMsg();
  }, [global.linking]);

  return global.linking ? (
    <Box sx={{ ...container, ...messageBox }}>
      <LoadingWheel width={4} />
      <Typography sx={bigText} color="primary">
        {constants.LINK_HANG}
      </Typography>
      <Typography color="primary">{linkMsg}..</Typography>
    </Box>
  ) : (
    <Box sx={container}>
      {global.signLoad && <LoadingModal width={6} />}
      <img
        src="images\BI-Tool_LogIn-Graphic-01.png"
        alt="BI Tool Login"
        width="250rem"
      />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            p={1}
            mt={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Select
              value={currentProject}
              renderValue={(value) => value.name}
              sx={regionSelect}
              startAdornment={
                <Box sx={flagDisplayBox}>
                  {currentProject.icons.map((icon) => (
                    <Box key={icon} sx={{ fontSize: "1rem", mr: "0.25rem" }}>
                      {icon}
                    </Box>
                  ))}
                </Box>
              }>
              {availabeProjects.map((project) => (
                <MenuItem key={project} component="a" href={project.url}>
                  <Grid container height="1.5rem">
                    <Grid
                      item
                      xs={3}
                      sx={{
                        p: "0.25rem",
                        mr: "0.5rem",
                      }}>
                      <Box
                        sx={{
                          display: "flex",
                          left: "1rem",
                        }}>
                        {project.icons.map((icon) => (
                          <Box
                            key={icon}
                            sx={{ fontSize: "1rem", mr: "0.25rem" }}>
                            {icon}
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: "flex", alignItems: "center" }}>
                      <Typography>{project.name}</Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </Select>
            <Box p={1} sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                label="Email"
                id="email"
                value={email}
                {...register("email", {
                  required: "required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: constants.SIGNIN_BAD_EMAIL,
                  },
                })}
                sx={inputStyle}
                type="email"
              />
              {errors.email && (
                <Typography sx={{ fontSize: "1rem" }} variant="error">
                  {errors.email.message}
                </Typography>
              )}
            </Box>

            <Box p={1} sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                label="Password"
                id="password"
                {...register("password", {
                  required: "required",
                  minLength: {
                    value: 5,
                    message: constants.SIGNIN_SHORT_PW,
                  },
                })}
                type={textType}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton onClick={() => handleIconClick(textType)}>
                        {textType === "password" ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={inputStyle}
              />
              {errors.password && (
                <Typography sx={fontStyle} variant="error">
                  {errors.password.message}
                </Typography>
              )}
              {global.signError && (
                <Typography sx={fontStyle} variant="error">
                  {global.signError}
                </Typography>
              )}
            </Box>
            <Box
              p={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                sx={{ width: "23rem", mb: "1rem" }}
                color="info"
                variant="contained"
                type="submit"
              >
                {constants.SIGNIN_BTN}
              </Button>
              <Button
                sx={{ width: "23rem", mb: "1rem" }}
                color="info"
                variant="outlined"
                onClick={onSubmitGoogle}
              >
                {constants.SIGNIN_GOOGLE_BTN}
              </Button>
              <Button
                onClick={forgotPassClick}
                sx={{ width: "12rem", fontSize: ".75rem" }}
              >
                {constants.SIGNIN_FORGOT_PASSWORD}
              </Button>
              <Typography variant="error2">{falert}</Typography>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default SignIn;
